import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { FieldValues, useForm } from 'react-hook-form';
import { useState } from 'react';
import { AutocompleteElement, FormContainer } from 'react-hook-form-mui';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header/Header';
import AuditStamp from '../AuditStamp/AuditStamp';
import createFormTextField from '../../components/FormTextFields/FormTextFields';
import useDialog from '../../utils/hooks/useDialog';
import UnsavedFormDataPrompt from '../../components/UnsavedDataPrompt/UnsavedFormDataPrompt';
import { useSnackbar } from '../../contexts/SnackbarContext';
import FormFooter from '../../components/StickyFooter/FormFooter';
import LaborSettingsScheduleChangeWarning from './LaborSettingsScheduleChangeWarning';

export default function LaborSettingsSchedulePage() {
  const { t } = useTranslation();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formData, setFormData] = useState<any>();
  const time = new Date();
  const auditStamp = {
    createdAt: time.toString(),
    createdBy: '',
    updatedAt: time.toString(),
    updatedBy: 'singstringstringstringstring',
    deletedAt: time.toString(),
    deletedBy: 'stringdeleted',
  };

  const formContext = useForm();
  const { setSnackbarState } = useSnackbar();
  const {
    watch,
    formState: { isDirty },
  } = formContext;
  const watchedClockIn = watch('WorkDayStartTime');
  const theme = useTheme();
  const { open: openConfirmDialog, close: closeConfirmDialog, isOpen: isConfirmDialogOpen } = useDialog();

  const workWeeks = [
    { label: t('weekDay.sunday'), id: 'Sunday', value: 'Sunday' },
    { label: t('weekDay.monday'), id: 'Monday', value: 'Monday' },
    { label: t('weekDay.tuesday'), id: 'Tuesday', value: 'Tuesday' },
    { label: t('weekDay.wednesday'), id: 'Wednesday', value: 'Wednesday' },
    { label: t('weekDay.thursday'), id: 'Thursday', value: 'Thursday' },
    { label: t('weekDay.friday'), id: 'Friday', value: 'Friday' },
    { label: t('weekDay.saturday'), id: 'Saturday', value: 'Saturday' },
  ];

  const [attemptedCloseWhileDirty, setAttemptedCloseWhileDirty] = useState(false);
  const [translationKeyBool, setTranslationKeyBool] = useState(false);
  const closeDialog = () => {
    setTranslationKeyBool(false);
    setAttemptedCloseWhileDirty(false);
    formContext.reset();
  };
  const cancelDailog = () => {
    setAttemptedCloseWhileDirty(false);
    setTranslationKeyBool(false);
  };
  const workDayStartTimeField: FieldValues = {
    name: 'WorkDayStartTime',
    required: true,
    variant: 'time',
    gridProps: { xs: 12, lg: 12, md: 12 },
    dataTestId: 'WorkdayStartTme',
    isInputComplete: !!watchedClockIn,
    lightMode: theme.palette.mode === 'light',
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitForm = async (data: any) => {
    setSnackbarState({
      open: true,
      message: `${t('labor.formSuccessMessage')}`,
      color: 'success',
    });
  };
  const handleSubmitForm = async () => {
    closeConfirmDialog();
    formContext.reset(formData);
    await submitForm(formData);
  };
  const attemptCloseDialog = () => {
    setTranslationKeyBool(true);
    return isDirty ? setAttemptedCloseWhileDirty(true) : closeDialog();
  };
  return (
    <Box data-testid='labor-settings-schedule' marginLeft={4} marginTop={3}>
      <Grid xs={12} item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Grid minWidth={120} width={800}>
          <Header
            description={t('labor.ScheduleSettingsDescription')}
            maxDescriptionWidth='600px'
            title={t('labor.ScheduleSettingHeader')}
            dataTestId='labor-schedule-setting-header'
          />
        </Grid>
        <Grid sx={{ marginRight: '2rem' }}>
          <AuditStamp entity={auditStamp} />
        </Grid>
      </Grid>
      <FormContainer
        formContext={formContext}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSuccess={async (data: any) => {
          setFormData(data);
          openConfirmDialog();
          // await submitForm(data);
        }}
      >
        <Grid container spacing={2} mb={2} justifyContent='flex-start'>
          <Grid item xs={3} lg={3} mb={2} md={3.5} justifyContent='flex-start' sx={{ marginTop: '2rem' }}>
            {createFormTextField(workDayStartTimeField, { t, tKey: 'labor' })}
          </Grid>
          <Grid item xs={4} lg={7} md={5} sx={{ marginTop: '3.7rem', marginLeft: '1rem' }}>
            <Typography
              sx={{
                fontWeight: '400',
              }}
              data-testid='desc-For-WorkDayStartTime'
            >
              {t('laborSettingsForSchedule.descForWorkDayStartTime')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2} justifyContent='flex-start'>
          <Grid item xs={3} lg={3} md={3.5} justifyContent='flex-start'>
            <AutocompleteElement
              label={t('labor.WorkWeekStartDay')}
              required
              name='WorkWeekStartDay'
              aria-controls='WorkWeekStartDay-autocomplete-textfield'
              textFieldProps={{
                variant: 'standard',
                id: 'WorkWeekStartDay-autocomplete-textfield',
              }}
              options={workWeeks}
            />
          </Grid>
          <Grid item xs={4} md={5} lg={7} sx={{ marginTop: '1.6rem', marginLeft: '1rem' }}>
            <Typography
              sx={{
                fontWeight: '400',
              }}
              data-testid='desc-For-WorkWeekStartDay'
            >
              {t('laborSettingsForSchedule.descForWorkWeekStartDay')}
            </Typography>
          </Grid>
        </Grid>
        {isDirty && (
          <FormFooter
            buttons={[
              { onClick: attemptCloseDialog, dataTestId: 'close-button', name: t('buttonText.discardChanges') },
              {
                dataTestId: 'save-button',
                submitType: true,
              },
            ]}
          />
        )}

        <LaborSettingsScheduleChangeWarning
          open={isConfirmDialogOpen}
          onCancel={closeConfirmDialog}
          onConfirm={handleSubmitForm}
        />

        <UnsavedFormDataPrompt
          open={attemptedCloseWhileDirty}
          onConfirm={closeDialog}
          onCancel={cancelDailog}
          when={isDirty}
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={() => isDirty}
          textTranslationKey={translationKeyBool ? 'unsavedDataDialogForCancelButton' : 'unsavedDataDialog'}
        />
      </FormContainer>
    </Box>
  );
}
