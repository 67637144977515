import AccountCircle from '@mui/icons-material/AccountCircle';
import DomainIcon from '@mui/icons-material/Domain';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IDToken, Token } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FeatureFlag } from '@cbo/shared-library';
import { useShell } from '../../../pages/Shell';
import SiteSelector from '../../admin/SiteSelector/SiteSelector';
import { useAuth } from '../../auth/AuthWrapper/AuthWrapper';
import routes from '../../constants/routes';
import { useUsers } from '../../contexts/userContext';
import { useOrgContext } from '../../org/CommonComponentWrapper';
import OrganizationSwitcher from '../../org/OrganizationSwitcher';
import recordLogoutEvent from '../../services/AuditService';
import { useSidebar } from '../SidebarNavigation/ncr-design-system-components/CustomSidebar/CustomSidebarContext';
import { useFeatureFlag } from '../../utils/hooks/useFeatureFlag';

interface AppBarProps {
  hasUnreadNotifications: boolean;
}

interface OktaError {
  name: string;
  errorCode?: number;
  xhr: {
    message: string;
  };
}

export const APPBAR_HEIGHT = '72px';

/** The content that will go in the app bar */
function AppBar({ hasUnreadNotifications }: AppBarProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { setRightPanel } = useShell();
  const { isCollapsable, setCollapsed, isCollapsed } = useSidebar();
  const { setCorsErrorModalOpen } = useAuth();
  const { t } = useTranslation();
  const { pathname: currentUrl } = useLocation();
  const user = useUsers();
  const [menuAnchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const [isOrgView, setIsOrgView] = useState(false);
  const [isDrillDownView, setIsDrillDownView] = useState(false);
  const { organization, userOrganizations } = useOrgContext();
  const cboLaborRearchitecture = useFeatureFlag(FeatureFlag.laborRearchitecture);

  useEffect(() => {
    const orgRoutes: string[] = [
      routes.SITES,
      routes.FISCAL_CALENDAR,
      routes.SITE_GROUPS,
      routes.ACCOUNT_PREFERENCES,
      routes.NOTIFICATION_SETTINGS,
      routes.ACTIVITY_LOG,
      routes.SITE_TAGS,
      routes.LABOR_RULES,
      routes.INVOICE_HISTORY_REPORT,
      routes.UNFINALIZED_GL_ACCOUNTS_REPORT,
      routes.UNFINALIZED_VENDORS_REPORT,
      routes.GL_ACCOUNT_MAPPING,
      routes.MANAGE_GL_ACCOUNTS,
      routes.HOUSE_ACCOUNTS,
      routes.SALES_DEFINITIONS_SETTINGS,
      routes.LABOR_SETTINGS_SCHEDULE,
    ];

    if (cboLaborRearchitecture) {
      orgRoutes.push(routes.JOBCODE_CONFIGURATION_PAGE);
    }

    if (orgRoutes.some((route) => currentUrl.includes(route))) {
      setIsOrgView(true);
    } else {
      setIsOrgView(false);
    }

    if (
      [
        routes.EMPLOYEE_OVERVIEW_FOCUSMODE_BASE,
        routes.HOUSE_ACCOUNT_FOCUSMODE_BASE,
        routes.JOBCODE_CONFIGURATION_FOCUSMODE_BASE,
        routes.JOBCODE_EMPLOYEES_FOCUSMODE_BASE,
      ].some((focusModePath) => currentUrl.includes(focusModePath) && currentUrl.split(focusModePath)[1].length <= 1)
    ) {
      setIsDrillDownView(false);
    } else if (
      [
        routes.EMPLOYEE_PAGE,
        routes.JOBCODE_CONFIGURATION_PAGE,
        routes.INVOICES,
        routes.VENDORS,
        routes.RAW_ITEMS,
        routes.HOUSE_ACCOUNTS,
        routes.SITE_GROUPS,
        routes.SITE_TAGS,
        routes.NOTIFICATION_SETTINGS,
        routes.LABOR_RULES,
      ].some(
        (baseRoutes) =>
          currentUrl.includes(routes.EMPLOYEE_REGISTRATION_HOME) ||
          (currentUrl.includes(baseRoutes) && currentUrl.split(baseRoutes)[1].length > 1)
      )
    ) {
      setIsDrillDownView(true);
    } else {
      setIsDrillDownView(false);
    }
  }, [cboLaborRearchitecture, currentUrl]);

  const handleUsernameClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const isCorsError = (oktaError: OktaError): boolean =>
    oktaError.name === 'AuthApiError' && !oktaError.errorCode && oktaError.xhr.message === 'Failed to fetch';

  const toggleSidebar = (): void => {
    setCollapsed((currentState) => !currentState);
  };

  const { oktaAuth } = useOktaAuth();
  const login = async () => {
    oktaAuth.signInWithRedirect();
  };
  const logout = async () => {
    handleMenuClose();
    try {
      oktaAuth.tokenManager.on('removed', async (key: string, token: Token) => {
        const { org, bslAuth } = user;
        const { email, name } = (token as IDToken).claims;
        const keyIsIdToken = key === 'idToken';
        const isAuthenticated = user.firebaseStatus === 'authenticated';
        if (org && email && name && keyIsIdToken && isAuthenticated && bslAuth) {
          const { sites } = bslAuth;
          const siteId = sites[Object.keys(sites)[0]]?.enterpriseUnitId || 'unknown';
          const organizationId = org.bslId;
          await recordLogoutEvent({ email, name, organizationId, siteId });
        }
      });
      await oktaAuth.signOut({ clearTokensBeforeRedirect: false });
      sessionStorage.setItem('showLogin', 'true');
    } catch (err: unknown) {
      if (isCorsError(err as OktaError)) {
        setCorsErrorModalOpen(true);
      }
      throw err;
    }
  };

  return (
    <Box
      padding={2}
      display='flex'
      position='sticky'
      top={0}
      justifyContent='space-between'
      alignItems='center'
      gap={2}
      width='100%'
      height={APPBAR_HEIGHT}
      zIndex='1000'
      sx={{ backgroundColor: 'background.default' }}
    >
      <Box>
        {isCollapsable && (
          <IconButton
            onClick={toggleSidebar}
            data-testid='toggle-sidebar'
            name='sidebar-menu'
            aria-label='Toggle Navigation Menu'
            className={!isCollapsed ? 'open-sidebar' : 'sidebar'}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Box>
      <Box>
        {user.oktaStatus === 'authenticated' && (
          <Box display='flex' gap={isMobile ? 1 : 3} alignItems='center'>
            {userOrganizations?.length !== 1 && !isDrillDownView ? (
              <OrganizationSwitcher />
            ) : (
              <span>
                <Button disabled data-testid='drill-down-view-btn' startIcon={<DomainIcon />}>
                  {organization?.displayName}
                </Button>
              </span>
            )}
            {!user.isOrgSwitching && organization && <SiteSelector isOrgView={isOrgView} />}
            <Box>
              <Button
                onClick={handleUsernameClick}
                sx={{
                  color: 'text.secondary',
                  fontWeight: 'normal',
                  '&:visited, &:focus, &:active, &:hover, &:focus:hover': {
                    color: 'text.secondary',
                    backgroundColor: 'transparent',
                  },
                }}
                data-testid='toggle-user-dropdown-menu'
                startIcon={<AccountCircle />}
              >
                {isMobile ? null : user.profile?.name}
              </Button>
              <Menu
                open={isMenuOpen}
                anchorEl={menuAnchorEl}
                onClose={handleMenuClose}
                sx={{
                  '.MuiPaper-root': {
                    borderRadius: '4px',
                    boxShadow: 5,
                    color: 'text.secondary',
                  },
                  zIndex: 1450,
                }}
                data-testid='user-dropdown-menu'
              >
                <MenuItem dense onClick={handleMenuClose} data-testid='my-account-menu-item'>
                  <RouterLink
                    to={routes.ACCOUNT_PREFERENCES}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    data-testid='my-account-link-item'
                  >
                    {t('header.myAccount')}
                  </RouterLink>
                </MenuItem>
                <MenuItem dense onClick={logout} data-testid='logout-menu-item'>
                  {t('buttonText.logout')}
                </MenuItem>
              </Menu>
            </Box>
            {/* Notification */}
            {cboLaborRearchitecture && (
              <IconButton aria-label='Notifications' onClick={() => setRightPanel('notifications')}>
                <Badge color='primary' variant='dot' invisible={!hasUnreadNotifications}>
                  <NotificationsIcon data-testid='notifications-icon' />
                </Badge>
              </IconButton>
            )}
          </Box>
        )}

        {user.oktaStatus === 'unauthenticated' && (
          <Button variant='outlined' sx={{ ml: 1, mr: 1 }} data-testid='login-button' onClick={login}>
            {t('buttonText.login')}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default AppBar;
